$.lui("part", ".part_occupancy_radial", function(selector) {
    let progressElm = selector.find('[data-target="progress"]')[0];
    let percentageElm = selector.find('[data-target="percentage"]')[0];
    let totalLength = 157;
    let max = selector.data('max');
    let queryUrl = selector.data('query-url');
    let timeout = selector.data('timeout');
    let timeoutVariable = null;

    function setProgress(current) {
        let percentage = Math.min((current / max), 1)
        let offset = totalLength * (1 - percentage);
        progressElm.setAttribute('stroke-dashoffset',Math.ceil(offset));
        percentageElm.textContent = `${Math.round(percentage * 100)} %`;
    }

    function makeRequest() {
        clearTimeout(timeoutVariable);
        try {
            fetch(queryUrl, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }).then(function (response) {
                if(!response.ok) {
                    throw new Error(response.status);
                }

                response.json().then(function (data) {
                    let current = Math.ceil(data.current);
                    setProgress(current);
                    timeoutVariable = setTimeout(function () {
                        makeRequest()
                    }, timeout)
                })
            });
        } catch (e) {
            console.error(e);
        }
    }

    makeRequest()
});
